/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Nov 12 2024
 */

// 'root'
export const CompanyList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "company-list" */
  '@/views/Admin/RootManagement/CompanyList.vue'
);
export const MutateCompany = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-company" */
  '@/views/Admin/RootManagement/MutateCompany.vue'
);

// 'root' + 'site-admin'
export const UserList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-list" */
  '@/views/Admin/RoleManagement/UserList.vue'
);
export const AddUser = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "add-user" */
  '@/views/Admin/RoleManagement/AddUser.vue'
);
export const EditUser = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "edit-user" */
  '@/views/Admin/RoleManagement/EditUser.vue'
);

// 'course-admin' + 'site-admin'
export const StudentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "student-list" */
  '@/views/Admin/RoleManagement/StudentList.vue'
);
export const EnrollmentList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "enrollment-list" */
  '@/views/Admin/PaymentManagement/EnrollmentList.vue'
);
export const UploadEnrollments = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "upload-enrollments" */
  '@/views/Admin/PaymentManagement/UploadEnrollments.vue'
);
export const CouponList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "coupon-list" */
  '@/views/Admin/PaymentManagement/CouponList.vue'
);
export const MutateCoupon = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-coupon" */
  '@/views/Admin/PaymentManagement/MutateCoupon.vue'
);
export const NewsList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "news-list" */
  '@/views/Admin/WebsiteManagement/NewsList.vue'
);
export const MutateNews = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-news" */
  '@/views/Admin/WebsiteManagement/MutateNews.vue'
);
export const HomeBannerList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home-banner-list" */
  '@/views/Admin/WebsiteManagement/HomeBannerList.vue'
);
export const MutateHomeBanner = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-home-banner" */
  '@/views/Admin/WebsiteManagement/MutateHomeBanner.vue'
);

// 'course-admin' + 'content-admin'
export const QuestionPoolList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-pool-list" */
  '@/views/Admin/ExamManagement/QuestionPoolList.vue'
);
export const MutateQuestionPool = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question-pool" */
  '@/views/Admin/ExamManagement/MutateQuestionPool.vue'
);
export const QuestionTagList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-tag-list" */
  '@/views/Admin/ExamManagement/QuestionTagList.vue'
);
export const MutateQuestionTag = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question-tag" */
  '@/views/Admin/ExamManagement/MutateQuestionTag.vue'
);
export const QuestionList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "question-list" */
  '@/views/Admin/ExamManagement/QuestionList.vue'
);
export const MutateQuestion = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-question" */
  '@/views/Admin/ExamManagement/MutateQuestion.vue'
);
export const UploadQuestions = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "upload-questions" */
  '@/views/Admin/ExamManagement/UploadQuestions.vue'
);
export const SubjectList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "subject-list" */
  '@/views/Admin/CourseManagement/SubjectList.vue'
);
export const MutateSubject = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-subject" */
  '@/views/Admin/CourseManagement/MutateSubject.vue'
);
export const YoutubeCourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "youtube-course-list" */
  '@/views/Admin/CourseManagement/YoutubeCourseList.vue'
);
export const MutateYoutubeCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-youtube-course" */
  '@/views/Admin/CourseManagement/MutateYoutubeCourse.vue'
);

// 'course-admin' only
export const SubProgramList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "sub-program-list" */
  '@/views/Admin/CourseManagement/SubProgramList.vue'
);
export const MutateSubProgram = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-sub-program" */
  '@/views/Admin/CourseManagement/MutateSubProgram.vue'
);
export const CourseList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-list" */
  '@/views/Admin/CourseManagement/CourseList.vue'
);
export const MutateCourse = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-course" */
  '@/views/Admin/CourseManagement/MutateCourse.vue'
);
export const InstructorList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "instructor-list" */
  '@/views/Admin/RoleManagement/InstructorList.vue'
);
export const MutateInstructor = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-instructor" */
  '@/views/Admin/RoleManagement/MutateInstructor.vue'
);
export const DynamicPageList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-dynamic-page-list" */
  '@/views/Admin/WebsiteManagement/DynamicPageList.vue'
);
export const MutateDynamicPage = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-mutate-dynamic-page" */
  '@/views/Admin/WebsiteManagement/MutateDynamicPage.vue'
);
export const FacebookSettings = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "facebook-settings" */
  '@/views/Admin/SettingsManagement/FacebookSettings.vue'
);
export const MaterialList = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "material-list" */
  '@/views/Admin/CourseManagement/MaterialList.vue'
);
export const MutateMaterial = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-material" */
  '@/views/Admin/CourseManagement/MutateMaterial.vue'
);
export const MutateQuiz = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "mutate-quiz" */
  '@/views/Admin/ExamManagement/MutateQuiz.vue'
);

// Auth
export const AuthView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
export const LoginView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);

// Individual
export const UserView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-view" */
  '@/views/Individual/UserView.vue'
);
export const AccountView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "individual/user-account-view" */
  '@/views/Individual/AccountView.vue'
);

// All
export const AdminTemplate = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "admin-template" */
  '@/views/Admin/AdminTemplate.vue'
);
