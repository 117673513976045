/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import type {
  GetSubProgramsDto,
  GetSubjectsDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  coursePrograms,
} from 'utilities';
import {
  handleAxiosError,
} from '@/helpers';
import {
  ChangeTypeOfKeys,
  IQuestionTag,
  ISubProgram,
  ISubject,
} from '@/type';

export const useBackendStore = defineStore('backend', {
  state: () => ({
    subjectsForProgram: {
    } as Record<string, ISubject[]>,
    subProgramsForProgram: {
    } as Record<string, ISubProgram[]>,
    questionTags: [] as IQuestionTag[],
  }),
  actions: {
    async initOnAuthChange(token: string): Promise<void> {
      if (!token) {
        this.subjectsForProgram = {
        };
        this.subProgramsForProgram = {
        };
        this.questionTags = [];
        return;
      }

      this.fetchSubjectsForProgram();
      this.fetchQuestionTags();
      this.fetchSubProgramsForProgram();
    },
    async fetchSubjectsForProgram(programs = coursePrograms): Promise<void> {
      const subjectsForProgramPromise = async (program: string): Promise<void> => {
        try {
          type FetchDocsParams = ChangeTypeOfKeys<GetSubjectsDto, 'filterBy' | 'fields', string | undefined>;

          const params: FetchDocsParams = {
            fields: '_id,title,program,chapters',
            search: program,
            filterBy: 'program',
          };

          const response = await axios.get<{
            docs: ISubject[]; totalData: number;
          }>('/subjects', {
            params,
          });

          this.subjectsForProgram[program] = response.data.docs;
        } catch (e: any) {
          const errMessage = `Error in getting subjects for program ${program}`;
          handleAxiosError(e, errMessage);
        }
      };

      const subjectPromises = programs.map((p) => subjectsForProgramPromise(p));
      await Promise.all(subjectPromises);
    },
    async fetchQuestionTags(): Promise<void> {
      try {
        const response = await axios.get('/question-tags');
        this.questionTags = response.data.docs;
      } catch (e: any) {
        const errMessage = 'Error in getting question tags';
        handleAxiosError(e, errMessage);
      }
    },
    async fetchSubProgramsForProgram(programs = coursePrograms): Promise<void> {
      const subProgramsForProgramPromise = async (program: string): Promise<void> => {
        try {
          type FetchDocsParams = ChangeTypeOfKeys<GetSubProgramsDto, 'filterBy' | 'fields', string | undefined>;

          const params: FetchDocsParams = {
            fields: ['_id', 'title'].join(','),
            filterBy: 'program',
            search: program,
          };

          const response = await axios.get('/sub-programs', {
            params,
          });

          this.subProgramsForProgram[program] = response.data.docs;
        } catch (e: any) {
          handleAxiosError(e, `Error in fetching subprograms for program ${program}`);
        }
      };

      const subProgramPromises = programs.map((p) => subProgramsForProgramPromise(p));
      await Promise.all(subProgramPromises);
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
