/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Nov 12 2024
 */

import {
  RouteRecordRaw,
} from 'vue-router';
import {
  AccountView,
  AddUser,
  AdminTemplate,
  AuthView,
  CompanyList,
  CouponList,
  CourseList,
  DynamicPageList,
  EditUser,
  EnrollmentList,
  FacebookSettings,
  HomeBannerList,
  InstructorList,
  LoginView,
  MaterialList,
  MutateCompany,
  MutateCoupon,
  MutateCourse,
  MutateDynamicPage,
  MutateHomeBanner,
  MutateInstructor,
  MutateMaterial,
  MutateNews,
  MutateQuestion,
  MutateQuestionPool,
  MutateQuestionTag,
  MutateQuiz,
  MutateSubProgram,
  MutateSubject,
  MutateYoutubeCourse,
  NewsList,
  QuestionList,
  QuestionPoolList,
  QuestionTagList,
  StudentList,
  SubProgramList,
  SubjectList,
  UploadEnrollments,
  UploadQuestions,
  UserList,
  UserView,
  YoutubeCourseList,
} from './component';
import {
  IUserRole,
} from '@/type';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    alias: '/',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: 'AccountView',
        component: AccountView,
        meta: {
          title: 'Account View',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: AdminTemplate,
    children: [
      {
        path: 'company-list',
        name: 'CompanyList',
        component: CompanyList,
        meta: {
          title: 'Company List',
        },
      },
      {
        path: 'company/add/:id',
        alias: 'company/edit/:id',
        name: 'MutateCompany',
        component: MutateCompany,
        meta: {
          title: 'Add/Edit Company',
        },
      },
      {
        path: 'user-list',
        name: 'AdminList',
        component: UserList,
        meta: {
          title: 'Admin List',
        },
      },
      {
        path: 'user/add',
        name: 'AddUser',
        component: AddUser,
        meta: {
          title: 'Add Admin',
        },
      },
      {
        path: 'user/edit/:id',
        name: 'EditUser',
        component: EditUser,
        meta: {
          title: 'Edit Admin',
        },
      },
      {
        path: 'course-list',
        name: 'CourseList',
        component: CourseList,
        meta: {
          title: 'Course List',
        },
      },
      {
        path: 'course/add/:id',
        alias: 'course/edit/:id',
        name: 'MutateCourse',
        component: MutateCourse,
        meta: {
          title: 'Add/Edit Course',
        },
      },
      {
        path: 'quiz/add/:id',
        alias: 'quiz/edit/:id',
        props: (route) => ({
          id: route.params.id,
          courseId: route.query.courseId,
          program: route.query.program,
          subjectId: route.query.subjectId,
          chapterId: route.query.chapterId,
        }),
        name: 'MutateQuiz',
        component: MutateQuiz,
        meta: {
          title: 'Add/Edit Quiz',
        },
      },
      {
        path: 'instructor-list',
        name: 'InstructorList',
        component: InstructorList,
        meta: {
          title: 'Instructor List',
        },
      },
      {
        path: 'instructor/add/:id',
        alias: 'instructor/edit/:id',
        name: 'MutateInstructor',
        component: MutateInstructor,
        meta: {
          title: 'Add/Edit Instructor',
        },
      },
      {
        path: 'enrollment-list',
        name: 'EnrollmentList',
        component: EnrollmentList,
        meta: {
          title: 'Enrollment List',
        },
      },
      {
        path: 'upload-enrollments',
        name: 'UploadEnrollments',
        component: UploadEnrollments,
        meta: {
          title: 'Upload Enrollments',
        },
      },
      {
        path: 'student-list',
        name: 'StudentList',
        component: StudentList,
        meta: {
          title: 'Student List',
        },
      },
      {
        path: 'coupon-list',
        name: 'CouponList',
        component: CouponList,
        meta: {
          title: 'Coupon List',
        },
      },
      {
        path: 'coupon/add/:id',
        alias: 'coupon/edit/:id',
        name: 'MutateCoupon',
        component: MutateCoupon,
        meta: {
          title: 'Add/Edit Coupon',
        },
      },
      {
        path: 'home-banner-list',
        name: 'HomeBannerList',
        component: HomeBannerList,
        meta: {
          title: 'Home Banner List',
        },
      },
      {
        path: 'home-banner/add/:id',
        alias: 'home-banner/edit/:id',
        name: 'MutateHomeBanner',
        component: MutateHomeBanner,
        meta: {
          title: 'Add/Edit Home Banner',
        },
      },
      {
        path: 'sub-program-list',
        name: 'SubProgramList',
        component: SubProgramList,
        meta: {
          title: 'Subprogram List',
        },
      },
      {
        path: 'sub-program/add/:id',
        alias: 'sub-program/edit/:id',
        name: 'MutateSubProgram',
        component: MutateSubProgram,
        meta: {
          title: 'Add/Edit Subprogram',
        },
      },
      {
        path: 'subject-list',
        name: 'SubjectList',
        component: SubjectList,
        meta: {
          title: 'Subject List',
        },
      },
      {
        path: 'subject/add/:id',
        alias: 'subject/edit/:id',
        name: 'MutateSubject',
        component: MutateSubject,
        meta: {
          title: 'Add/Edit Subject',
        },
      },
      {
        path: 'youtube-course-list',
        name: 'YoutubeCourseList',
        component: YoutubeCourseList,
        meta: {
          title: 'Free Course List',
        },
      },
      {
        path: 'youtube-course/add/:id',
        alias: 'youtube-course/edit/:id',
        name: 'MutateYoutubeCourse',
        component: MutateYoutubeCourse,
        meta: {
          title: 'Add/Edit Free Course',
        },
      },
      {
        path: 'news-list',
        name: 'NewsList',
        component: NewsList,
        meta: {
          title: 'Notice List',
        },
      },
      {
        path: 'news/add/:id',
        alias: 'news/edit/:id',
        name: 'MutateNews',
        component: MutateNews,
        meta: {
          title: 'Add/Edit Notice',
        },
      },
      {
        path: 'dynamic-page-list',
        name: 'DynamicPageList',
        component: DynamicPageList,
        meta: {
          title: 'Dynamic Page List',
        },
      },
      {
        path: 'dynamic-page/add/:id',
        alias: 'dynamic-page/edit/:id',
        name: 'MutateDynamicPage',
        component: MutateDynamicPage,
        meta: {
          title: 'Add/Edit Dynamic Page',
        },
      },
      {
        path: 'material-list/:courseId',
        name: 'MaterialList',
        component: MaterialList,
        meta: {
          title: 'Material List',
        },
      },
      {
        path: 'material/add/:courseId',
        name: 'AddMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Add Material',
        },
      },
      {
        path: 'material/edit/:id',
        name: 'EditMaterial',
        component: MutateMaterial,
        meta: {
          title: 'Edit Material',
        },
      },
      {
        path: 'question-list',
        name: 'QuestionList',
        component: QuestionList,
        meta: {
          title: 'Question List',
        },
      },
      {
        path: 'upload-questions',
        name: 'UploadQuestions',
        component: UploadQuestions,
        meta: {
          title: 'Upload Questions',
        },
      },
      {
        path: 'question/add/:id',
        alias: 'question/edit/:id',
        name: 'MutateQuestion',
        component: MutateQuestion,
        meta: {
          title: 'Add/Edit Question',
        },
      },
      {
        path: 'question-tag-list',
        name: 'QuestionTagList',
        component: QuestionTagList,
        meta: {
          title: 'Question Tag List',
        },
      },
      {
        path: 'question-tag/add/:id',
        alias: 'question-tag/edit/:id',
        name: 'MutateQuestionTag',
        component: MutateQuestionTag,
        meta: {
          title: 'Add/Edit Question Tag',
        },
      },
      {
        path: 'question-pool-list',
        name: 'QuestionPoolList',
        component: QuestionPoolList,
        meta: {
          title: 'Question Pool List',
        },
      },
      {
        path: 'question-pool/add/:id',
        alias: 'question-pool/edit/:id',
        name: 'MutateQuestionPool',
        component: MutateQuestionPool,
        meta: {
          title: 'Add/Edit Question Pool',
        },
      },
      // settings
      {
        path: 'settings/facebook',
        name: 'FacebookSettings',
        component: FacebookSettings,
        meta: {
          title: 'Facebook Settings',
        },
      },
    ],
  },
];

const ROUTE_ACCESS: Record<string, IUserRole[]> = {
  // 'root'
  '/admin/company-list': ['root'],
  '/admin/company/add': ['root'],
  '/admin/company/edit': ['root'],

  // 'root' + 'site-admin'
  '/admin/user-list': ['root', 'site-admin'],
  '/admin/user/add': ['root', 'site-admin'],
  '/admin/user/edit': ['root', 'site-admin'],

  // 'course-admin' + 'site-admin'
  '/admin/enrollment-list': ['course-admin', 'site-admin'],
  '/admin/upload-enrollments': ['course-admin', 'site-admin'],
  '/admin/student-list': ['course-admin', 'site-admin'],

  '/admin/coupon-list': ['course-admin', 'site-admin'],
  '/admin/coupon/add': ['course-admin', 'site-admin'],
  '/admin/coupon/edit': ['course-admin', 'site-admin'],

  '/admin/home-banner-list': ['course-admin', 'site-admin'],
  '/admin/home-banner/add': ['course-admin', 'site-admin'],
  '/admin/home-banner/edit': ['course-admin', 'site-admin'],

  '/admin/news-list': ['course-admin', 'site-admin'],
  '/admin/news/add': ['course-admin', 'site-admin'],
  '/admin/news/edit': ['course-admin', 'site-admin'],

  // 'course-admin' + 'content-admin'
  '/admin/question-list': ['course-admin', 'content-admin'],
  '/admin/question/add': ['course-admin', 'content-admin'],
  '/admin/question/edit': ['course-admin', 'content-admin'],
  '/admin/upload-questions': ['course-admin', 'content-admin'],

  '/admin/question-tag-list': ['course-admin', 'content-admin'],
  '/admin/question-tag/add': ['course-admin', 'content-admin'],
  '/admin/question-tag/edit': ['course-admin', 'content-admin'],

  '/admin/question-pool-list': ['course-admin', 'content-admin'],
  '/admin/question-pool/add': ['course-admin', 'content-admin'],
  '/admin/question-pool/edit': ['course-admin', 'content-admin'],

  '/admin/subject-list': ['course-admin', 'content-admin'],
  '/admin/subject/add': ['course-admin', 'content-admin'],
  '/admin/subject/edit': ['course-admin', 'content-admin'],

  '/admin/youtube-course-list': ['course-admin', 'content-admin'],
  '/admin/youtube-course/add': ['course-admin', 'content-admin'],
  '/admin/youtube-course/edit': ['course-admin', 'content-admin'],

  // 'course-admin' only
  '/admin/sub-program-list': ['course-admin', 'content-admin'],
  '/admin/sub-program/add': ['course-admin', 'content-admin'],
  '/admin/sub-program/edit': ['course-admin', 'content-admin'],

  '/admin/course-list': ['course-admin'],
  '/admin/course/add': ['course-admin'],
  '/admin/course/edit': ['course-admin'],

  '/admin/instructor-list': ['course-admin'],
  '/admin/instructor/add': ['course-admin'],
  '/admin/instructor/edit': ['course-admin'],

  '/admin/material-list': ['course-admin'],
  '/admin/material/add': ['course-admin'],
  '/admin/material/edit': ['course-admin'],

  '/admin/settings/facebook': ['course-admin'],

  '/admin/dynamic-page-list': ['course-admin'],
  '/admin/dynamic-page/add': ['course-admin'],
  '/admin/dynamic-page/edit': ['course-admin'],

  '/admin/quiz/add': ['course-admin'],
  '/admin/quiz/edit': ['course-admin'],
};

export function hasRouteAccess(routeName: string, userRole: IUserRole): boolean {
  const allowedRoles = ROUTE_ACCESS[routeName];
  // Default to true if no specific roles are defined
  return allowedRoles ? allowedRoles.includes(userRole) : true;
}
