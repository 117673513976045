const CONSTANTS = {
  ROUTES: {
    GUEST_HOME: '/auth/login',
    ADMIN_HOME: '/admin/user-list',
    COURSE_HOME: '/admin/course-list',
    CONTENT_HOME: '/admin/question-pool-list',
    ACCOUNT_PAGE: '/user/account',
  } as const,
} as const;

export const courseSettingsVideo = {
  title: 'How to fill up course settings form',
  url: 'https://drive.google.com/file/d/1LxLjPCAqRe9FBTrkQLYbEoW4H-s_hGZg/preview',
};

export const addChaptersVideo = {
  title: 'How to add chapters in a course',
  url: 'https://drive.google.com/file/d/1t-9QA-kHicvotrGS2nP9L4pfXB1xzLQN/preview',
};

export const addResourcesVideo = {
  title: 'How to add resources of a course',
  url: 'https://drive.google.com/file/d/1qOvYB7FNDyyIP0Cv4I0gJZWYeuFgcQ82/preview',
};

export const addQuizzesVideo = {
  title: 'How to add quiz and save the course',
  url: 'https://drive.google.com/file/d/1H7SEdlqXZlUdvGFk662dhAZpoKdEoW3A/preview',
};

export const quizSettingsVideo = {
  title: 'How to fill up quiz settings form',
  url: 'https://drive.google.com/file/d/1Ow9eNbA2MnnqhcHMPt2u58bT6xL04kgo/preview',
};

export const addQuestionsVideo = {
  title: 'How to add questions in a quiz',
  url: 'https://drive.google.com/file/d/1wwO2GAL3BxdmLL2wvCIDQBJ8T69CtIqB/preview',
};

export const selectQuestionPoolAndUploadQuestionVideo = {
  title: 'How to select question pool and upload question list',
  url: 'https://drive.google.com/file/d/1i-vVYCrsiKIgMbjf9Ix5sGYoyek00Ylb/preview',
};

export const addOrUploadQuestionsVideo = {
  title: 'How to add or upload questions in question list',
  url: 'https://drive.google.com/file/d/1fCgEuoN2IJ_2tXgwsKql3P6tVwYM3kpY/preview',
};

export const filterQuestionsVideo = {
  title: 'How to filter questions in question list',
  url: 'https://drive.google.com/file/d/1RH-bgHmpdgMVz2c3U6gxD8nS8h6H43fK/preview',
};

export const createAndAddQuestionPoolVideo = {
  title: 'How to create a question pool and add questions in the pool',
  url: 'https://drive.google.com/file/d/1m1l4UNwiH8lw3Z_GqbZnhLnDHg65Vxzz/preview',
};

export const editAndDeleteQuestionFromPoolVideo = {
  title: 'How to edit and delete questions from a pool',
  url: 'https://drive.google.com/file/d/1qASoypxH8TwVHdPejFmHGZTKicsD2Urc/preview',
};

export const addManuallyEnrolledInformation = {
  title: 'How to add manually enrolled students information to enrollment list',
  url: 'https://drive.google.com/file/d/1Ld5Wp9H8wF0fozU1rxPfTZfA8XszawA3/preview',
};

export default CONSTANTS;
